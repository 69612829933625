const ENVIRONMENT = {
  local: {
    PUSHER_APP_ID: '1422614',
    PUSHER_APP_KEY: '4e2b0d47eb13b28f1018',
    PUSHER_CLUSTER: 'ap1',
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    REACT_APP_IFRAME_URL: 'https://iframe.aquatic.dev.cloud.rovula.com',
    SOCKET_PATH: 'http://localhost:8081/socket',
    COGNITO_USER_POOL_ID: 'ap-southeast-1_jxvNMyvmV',
    COGNITO_CLIENT_ID: '7qldih684a40p9d4gr2athmdfv',
    SENTRY_URL: 'https://cbc9643d523c4c218e5a7c11f62a30e4@sentry.cloud.arv.co.th/25'
  },
  development: {
    PUSHER_APP_ID: '1422614',
    PUSHER_APP_KEY: '4e2b0d47eb13b28f1018',
    PUSHER_CLUSTER: 'ap1',
    REACT_APP_BACKEND_URL: '/api',
    REACT_APP_IFRAME_URL: 'https://iframe.aquatic.dev.cloud.rovula.com',
    SOCKET_PATH: '/socket',
    COGNITO_USER_POOL_ID: 'ap-southeast-1_jxvNMyvmV',
    COGNITO_CLIENT_ID: '7qldih684a40p9d4gr2athmdfv',
    SENTRY_URL: 'https://cbc9643d523c4c218e5a7c11f62a30e4@sentry.cloud.arv.co.th/25'
  },
  qa: {
    PUSHER_APP_ID: '1422615',
    PUSHER_APP_KEY: '11b994613d251ed34990',
    PUSHER_CLUSTER: 'ap1',
    REACT_APP_BACKEND_URL: '/api',
    REACT_APP_IFRAME_URL: 'https://iframe.aquatic.qa.cloud.rovula.com',
    SOCKET_PATH: '/socket',
    COGNITO_USER_POOL_ID: 'ap-southeast-1_xGvwPSMeX',
    COGNITO_CLIENT_ID: '5ftv9sgvegh0omm6vgj2m1a6cf',
    SENTRY_URL: 'https://cbc9643d523c4c218e5a7c11f62a30e4@sentry.cloud.arv.co.th/25'
  },
  production: {
    PUSHER_APP_ID: '1422616',
    PUSHER_APP_KEY: 'ae5511e685bde4509554',
    PUSHER_CLUSTER: 'ap1',
    REACT_APP_BACKEND_URL: '/api',
    REACT_APP_IFRAME_URL: 'https://iframe.aquatic.rovula.com',
    SOCKET_PATH: '/socket',
    COGNITO_USER_POOL_ID: 'ap-southeast-1_DPQBq6gf2',
    COGNITO_CLIENT_ID: '2cm824j6ubvl6f4r0vp7u8nkdq',
    SENTRY_URL: 'https://5ec83d95979b40b681e0fd516333ad15@o514220.ingest.sentry.io/6752274'
  }
};

const getValue = (key) => {
  const NODE_ENV = process.env.REACT_APP_NODE_ENVIROMENT || 'local';
  const value = ENVIRONMENT[NODE_ENV][key];
  if (!value) {
    throw new Error(`config error - missing env.${key}`);
  }

  return value;
};
const config = { getValue };
export default config;
