import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist';
import userSlice from 'sections/authentication/login/loginSlice';
import forgotPasswordSlice from 'sections/authentication/forgotPassword/forgotPasswordSlice';
import videoUploadSlice from 'sections/@dashboard/video/videoUploadSlice';
import videoProcessSlice from 'sections/@dashboard/video/videoProcessSlice';
import videoListSlice from 'sections/@dashboard/app/videoListSlice';
import videoPlayingSlice from 'sections/@dashboard/app/videoPlayingSlice';

const rootReducer = combineReducers({
  userSlice,
  forgotPasswordSlice,
  videoUploadSlice,
  videoProcessSlice,
  videoListSlice,
  videoPlayingSlice
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userSlice'],
  blacklist: ['']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
  devTools: true
});

const persistor = persistStore(store);

export { store, persistor };
