import { createSlice } from '@reduxjs/toolkit';

const forgotPasswordSlice = createSlice({
  name: 'forgotPasswordSlice',
  initialState: {
    email: '',
    code: ''
  },
  reducers: {
    setEmailForgotPassword: (state, action) => {
      state.email = action.payload; // set the new card visible immediately
    },
    setCodeForgotPassword: (state, action) => {
      state.code = action.payload; // set the new card visible immediately
    },
    removeForgotPasswordData: (state, action) => {
      state.email = '';
      state.code = '';
    }
  }
});

export const { setEmailForgotPassword, setCodeForgotPassword, removeForgotPasswordData } =
  forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
