/* eslint-disable complexity */
/* eslint-disable no-console */
import Config from 'config';
import Pusher from 'pusher-js';
import { store } from 'redux/store';
import { updateProcessProgress } from 'sections/@dashboard/video/videoProcessSlice';

const PUSHER_KEY = Config.getValue('PUSHER_APP_KEY');
const PUSHER_CLUSTER = Config.getValue('PUSHER_CLUSTER');

const pusher = new Pusher(PUSHER_KEY, {
  cluster: PUSHER_CLUSTER
  //   encrypted: true
});
const channel = pusher.subscribe('objects');
channel.bind('object.updated', (data) => {
  handleEventObjectUpdated(data);
});
// example data
// {
// 	"id": "e7fc08a7-7baa-428f-a411-b19d54e0bdf2",
// 	"processing": 15,
// 	"status": "processing",
//   }
const handleEventObjectUpdated = async (data) => {
  if (data) {
    store.dispatch(updateProcessProgress({ id: data.id, progress: data.processing }));
  }
};
